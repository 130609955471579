import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import BigHeader from "../components/bigHeader"
import ContentTeaser from "../components/contentTeaser"
import CustomerReviews from "../components/customerReviews"
import ThreeTeaser from "../components/threeTeaser"
import InfoBox from "../components/infoBox"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row } from "react-bootstrap"
import Image from "gatsby-image"

const siteMap = {
  items: [
    {label: "Leistungen", url: "/#leistungen"},
    {label: "Makler", url: "/#makler"},
    {label: "Referenzen", url: "/#referenzen"},
    {label: "Gratis Bewertung", url: "/#start", prominent: true},
  ]
}

const IndexPage = () => {
  const { header, team, abschluss, ref_1, ref_2, ref_3, ref_4, ref_5, ref_6 } = useStaticQuery(
    graphql`
      query {
        header: file(relativePath: { eq: "house.png" }) {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        team: file(relativePath: { eq: "team.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        versprechen: file(relativePath: { eq: "versprechen.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        abschluss: file(relativePath: { eq: "abschluss.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        ref_1: file(relativePath: { eq: "referenzen/1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ref_2: file(relativePath: { eq: "referenzen/2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ref_3: file(relativePath: { eq: "referenzen/3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ref_4: file(relativePath: { eq: "referenzen/4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ref_5: file(relativePath: { eq: "referenzen/5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ref_6: file(relativePath: { eq: "referenzen/6.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout siteMap={siteMap}>
      <SEO title="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
      <BigHeader
        title="Gratis Bewertung ihrer Immobilie"
        description="Erhalten Sie eine professionelle Werteinschätzung für Ihre Immobilie innerhalb 48 Std."
        img={header.childImageSharp.fluid}
      />
      <div id="leistungen"></div>
      <InfoBox
        title1="Bewertung"
        description1="Wir bewerten den Zustand und die Ausstattung Ihrer Immobilie, analysieren die Angebote im lokalen und regionalen Umfeld und erarbeiten Ihnen eine marktgerechte Bewertung."
        title2="Vergleichswertermittlung"
        description2="Diese Objektspezifische Vergleichswertermittlung enthält den Immobilienindex der Umgebung, regionale Kaufpreisinformationen, Vergleichsobjekte und Ausstattungsmerkmale."
        title3="Beratung"
        description3="Wir beraten Sie bei der Frage des optimalen Verkaufszeitpunktes. Hierdurch realisieren Sie den optimalen Verkaufspreis für Ihr Zuhause / Ihre Wertanlage!"
      />
      <div className="bg-gradient">
        {/* <ContentTeaser
          additionalClass=""
          category="Professionelle Bewertung"
          title="Was können Sie erwarten"
          description="Wir bewerten den Zustand und die Ausstattung Ihrer Immobilie, analysieren die Angebote im lokalen und regionalen Umfeld und erarbeiten Ihnen eine marktgerechte Bewertung. Diese Objektspezifische Vergleichswertermittlung enthält in circa über 14 Seiten eine Wohnumfeldinformationen, den Immobilienindex der Umgebung, regionale Kaufpreisinformationen, Angaben zur Immobilie, allgemeine Erläuterungen, Vergleichsobjekte, Ausstattungsmerkmale und natürlich unsere persönliche Einschätzung. Wir ermitteln den Verkehrswert der Immobilie, übernehmen die professionelle Ermittlung des Marktwertes und schätzen danach ein, welcher Verkaufserlös gegenwärtig und – unter Berücksichtigung der Marktentwicklung – zu einem späteren Zeitpunkt zu erzielen ist. Anhand dieser Daten beraten wir Sie bei der Frage des optimalen Verkaufszeitpunktes. Hierdurch realisieren Sie den optimalen Verkaufspreis für Ihr Zuhause / Ihre Wertanlage!"
          btnLabel=""
          btnTarget=""
          img={versprechen.childImageSharp.fluid}
        /> */}
        <div id="makler"></div>
        <ContentTeaser
          additionalClass=""
          category="Zuverlässigkeit / Beständigkeit"
          title="Ihr Immobilienmakler in Buchholz"
          description="Als zertifizierter Immobilienmakler (IHK) verfügen wir über hohe Fachkompetenz und über 20 Jahre Erfahrung in der Immobilienbranche. Unsere größte Stärke ist außergewöhnliche Marktnähe: Wir kennen den regionalen Markt in allen Facetten und die potentielle Käuferschaft mit ihren Bedürfnissen aus unmittelbarer Nachbarschaft. Egal ob Harburg oder Seevetal, ob in Buchholz oder in Lüneburg bis Winsen (Luhe) – wo Sie uns brauchen, sind wir für Sie da und begleiten Sie als vertrauensvoller Partner beim Verkauf Ihrer Immobilie in allen entscheidenden Phasen."
          btnLabel="Mehr erfahren"
          btnTarget="https://immobilienmakler-und-hausverwaltung.de/"
          img={team.childImageSharp.fluid}
        />
        <ContentTeaser
          additionalClass=""
          category="Unser Versprechen"
          title="Erhalten Sie eine Verkaufsgarantie"
          description="Garantierter Verkauf in 6 Monaten mit Entschädigungs­zahlungen bei Misserfolg"
          btnLabel="Mehr erfahren"
          btnTarget="https://immobilienmakler-und-hausverwaltung.de/"
          img={abschluss.childImageSharp.fluid}
        />

        <div id="referenzen"></div>
        <section className="referenzen pt-2">
          <Container fluid className="my-200">
            <Row>
              <div className="col-md-9 mx-md-auto">
                <Row>
                  <div className="col-12">
                    <h2 className="h3 mb-4">Einige der erfolgreich &amp; sorgenfrei vermittelten Immobilien</h2>
                    <p>Diese Immobilien wurden im Beratungsgespräch kostenlos bewertet und dann für unsere Kunden vollkommen sorgenfrei &amp; <br/>erfolgreich an einen passenden Interessenten vermittelt. Käufer &amp; Verkäufer sind begeistert!</p>
                    <Row className="mt-5">
                      <div className="col-md-4 mb-5">
                        <div className="bg-white rounded">
                          <Image fluid={ref_1.childImageSharp.fluid} alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
                          <div className="p-3">
                            <h3 className="h5">Doppelhaushälfte in Bendestorf</h3>
                            <p>Verkauft in 5 Wochen</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-5">
                        <div className="bg-white rounded">
                          <Image fluid={ref_2.childImageSharp.fluid} alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
                          <div className="p-3">
                            <h3 className="h5">Doppelhaushälfte in Brackel</h3>
                            <p>Verkauft in 6 Monaten</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-5">
                        <div className="bg-white rounded">
                          <Image fluid={ref_3.childImageSharp.fluid} alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
                          <div className="p-3">
                            <h3 className="h5">Wohnung in Hamburg Jenefeld</h3>
                            <p>Verkauft in 4 Wochen</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-5">
                        <div className="bg-white rounded">
                          <Image fluid={ref_4.childImageSharp.fluid} alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
                          <div className="p-3">
                            <h3 className="h5">Einfamilienhaus in Harsefeld</h3>
                            <p>Verkauft in 4 Monaten</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-5">
                        <div className="bg-white rounded">
                          <Image fluid={ref_5.childImageSharp.fluid} alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
                          <div className="p-3">
                            <h3 className="h5">Einfamilienhaus in Ketzendorf</h3>
                            <p>Verkauft in 3 Monaten</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-5">
                        <div className="bg-white rounded">
                          <Image fluid={ref_6.childImageSharp.fluid} alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
                          <div className="p-3">
                            <h3 className="h5">Doppelhaushälfte in Maschen</h3>
                            <p>Verkauft in 2 Monaten</p>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </section>

        <div id="start"></div>
      </div>
      
      <CustomerReviews
        title="Jetzt Gratis Bewertung anfordern"
        additionalClass=""
      />
      <ThreeTeaser
        title="Profitieren Sie von unserer langjährigen Erfahrung"
        content="Unsere Berater haben zwischen 5 und 45 Jahren Verkaufs-, Beratungs-, und Immobilienerfahren. Als Ausbildungsbetrieb werden junge Mitarbeiter optimal von den Erfahrungen anderer Generationen geleitet. Über 1.000 zufriedene Kunden im Bestand können nicht falsch liegen und gewährleisten eine zügige Vermarktung Ihrer Immobilie."
        title1="20"
        icon1="knowledge"
        description1="Jahre Erfahrung"
        title2="14"
        icon2="fa-user-friends"
        description2="Erfahrende Mitarbeiter"
        title3="> 100"
        icon3="fa-home"
        description3="verkaufte Immobilien"
      />
      <script dangerouslySetInnerHTML={{
        __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)} gtag('js', new Date()); gtag('config', 'AW-601692041');`
      }}></script> 
    </Layout>
  )
}

export default IndexPage
